import * as React from 'react';

import { Column } from './column';
import {
  TopSection,
  TopSectionInner,
  Wrapper,
  BottomSection,
  BottomSectionInner,
  Copyright,
  LegalNavigation,
  LegalNavigationItem,
  LegalNavigationLink,
} from './footer.styles';

export const FooterView = (props) => {
  const { theme, columns, copyright, legalLinks, withoutBorderTop, withoutTopSection } = props;

  return (
    <Wrapper
      theme={theme}
      withoutBorderTop={withoutBorderTop}
      withoutTopSection={withoutTopSection}
    >
      {!withoutTopSection && (
        <TopSection>
          <TopSectionInner>
            {columns.map(({ title, links }) => (
              <Column theme={theme} title={title} links={links} key={title} />
            ))}
          </TopSectionInner>
        </TopSection>
      )}

      <BottomSection>
        <BottomSectionInner>
          {theme === 'light' || theme === 'dark' ? (
            <>
              <LegalNavigation>
                {legalLinks.nodes.map(({ label, url }) => (
                  <LegalNavigationItem key={label}>
                    <LegalNavigationLink href={url}>{label}</LegalNavigationLink>
                  </LegalNavigationItem>
                ))}
              </LegalNavigation>
              <Copyright>{copyright}</Copyright>
            </>
          ) : (
            <>
              <Copyright>{copyright}</Copyright>
              <LegalNavigation>
                {legalLinks.nodes.map(({ label, url }) => (
                  <LegalNavigationItem key={label}>
                    <LegalNavigationLink href={url}>{label}</LegalNavigationLink>
                  </LegalNavigationItem>
                ))}
              </LegalNavigation>
            </>
          )}
        </BottomSectionInner>
      </BottomSection>
    </Wrapper>
  );
};
