import styled, { css } from 'styled-components';

import { fontSize, color, transition, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';

export const Wrapper = styled.footer`
  ${(p) => css`
    ${p.theme === 'dark' ||
    (p.theme === 'light' &&
      css`
        ${TopSectionInner} {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          column-gap: 40px;
          max-width: 1175px;
          box-shadow: inset 0 1px 0 0 #d9dfe4;
          padding-top: 62px;

          ${media.lg} {
            grid-template-columns: repeat(3, 1fr);
            row-gap: 40px;
            max-width: 960px;
          }

          ${media.md} {
            max-width: 720px;
          }

          ${media.sm} {
            grid-template-columns: repeat(2, 1fr);
            max-width: 540px;
          }

          ${media.xxs} {
            max-width: 100%;
          }

          ${media.xxs} {
            grid-template-columns: 1fr;
          }

          div {
            margin-right: 0;
          }
        }

        ${BottomSectionInner} {
          max-width: 1175px;
          ${media.lg} {
            max-width: 960px;
          }
          ${media.md} {
            max-width: 720px;
          }
          ${media.sm} {
            max-width: 540px;
          }
          ${media.xs} {
            max-width: 100%;
          }
        }
      `)}

    ${p.theme === 'dark' &&
    css`
      ${TopSection} {
        background-color: #2c295d;
        padding-top: 0;
        padding-bottom: 52px;
      }

      ${BottomSection} {
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
        padding-top: 9px;
        padding-bottom: 105px;
        background-color: #2c295d;
      }

      ${Copyright} {
        font-size: ${fontSize.xs};
        color: #ffffff;
        ${media.sm} {
          margin-top: 15px;
        }
      }

      ${LegalNavigationLink} {
        color: #ffffff;
      }

      ${LegalNavigationItem} {
        &::after {
          color: #ffffff;
        }
      }
    `}

    ${p.theme === 'light' &&
    css`
      ${TopSection} {
        background-color: rgba(255, 255, 255, 0);
        padding-top: 0;
        padding-bottom: 52px;
      }

      ${BottomSection} {
        padding-top: 9px;
        padding-bottom: 105px;
        background-color: rgba(255, 255, 255, 0);
      }

      ${Copyright} {
        font-size: ${fontSize.xs};
        color: #6b7696;
        ${media.sm} {
          margin-top: 15px;
        }
      }
    `}
  `}

  ${(p) => css`
    ${p.withoutBorderTop &&
    css`
      ${TopSectionInner} {
        box-shadow: none;
      }
    `}
  `}

  ${(p) => css`
    ${p.withoutTopSection &&
    css`
      ${BottomSection} {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    `}
  `}
`;

export const LegalNavigationLink = styled.a`
  font-size: ${fontSize.xs};
  line-height: 1;
  text-decoration: none;
  color: ${color.text.secondary};
  transition: ${transition.base};
  white-space: nowrap;

  &:hover {
    color: ${color.primary};
  }
`;

export const LegalNavigationItem = styled.li`
  position: relative;
  font-size: 0;
  margin-right: 15px;

  ${media.lg} {
    padding: 5px 0;
  }

  ${media.sm} {
    text-align: center;
    margin-right: 0;
    margin-bottom: 15px;
    padding: 0;
  }

  &:after {
    content: '•';
    position: absolute;
    top: 50%;
    right: -5px;
    font-size: ${fontSize.xs};
    color: ${color.text.secondary};
    transform: translateX(100%) translateY(-50%);

    ${media.sm} {
      display: none;
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const LegalNavigation = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  ${media.lg} {
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 650px;
  }

  ${media.sm} {
    display: block;
  }
`;

export const Copyright = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    text-align: center;
    margin-bottom: 25px;
  }
`;

export const BottomSectionInner = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;

  ${media.sm} {
    display: block;
  }
`;

export const BottomSection = styled.div`
  background-color: #090814;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const TopSectionInner = styled(Container)`
  display: flex;
  max-width: 1000px;

  ${media.sm} {
    justify-content: space-between;
  }

  ${media.xs} {
    flex-wrap: wrap;
    margin-bottom: -30px;
  }
`;

export const TopSection = styled.div`
  background-color: #0e0d1a;
  padding-top: 50px;
  padding-bottom: 50px;
`;
