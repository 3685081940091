import styled from 'styled-components';

import { fontSize, color, media, transition } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Field } from 'components/shared/field';
import { Modal } from 'components/shared/modal';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  padding-top: 153px;
  overflow: hidden;

  ${media.sm} {
    padding-top: 145px;
  }
`;

export const Inner = styled(Container)`
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -485px;
    transform: translateX(-50%);
    width: 1218px;
    height: 1218px;
    border-radius: 100%;
    background: linear-gradient(180deg, rgba(203, 234, 236, 0.24) 0%, #cbeaec 100%);
    z-index: -1;

    ${media.xs} {
      bottom: -298px;
      width: 818px;
      height: 818px;
    }
  }
`;

export const StyledSubtitle = styled.h2`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  color: #6b7696;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 9px;
`;

export const StyledTitle = styled(Title)`
  color: #2b303d;
  font-weight: normal;
  margin-bottom: 49px;

  ${media.sm} {
    font-size: 36px;
    margin-bottom: 31px;
  }
`;

export const CalculatorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 607px;
  background-color: ${color.text.tertiary};
  box-shadow: 2px 2px 52px -10px rgba(26, 26, 67, 0.05);
  border-radius: 8px;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 184px;

  ${media.xs} {
    padding-top: 14px;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 17px;
    margin-bottom: 52px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
    height: 673px;
    width: calc(100% + (28px * 2));
    background: linear-gradient(
      155.05deg,
      rgba(204, 156, 226, 0) 15.88%,
      rgba(0, 193, 222, 0.09) 91.55%
    );
    box-shadow: 2px 2px 52px -10px rgba(26, 26, 67, 0.05);
    border-radius: 8px;
    z-index: -1;

    ${media.xs} {
      top: 20px;
      height: 100%;
      width: calc(100% + (15px * 2));
    }
  }
`;

export const StyledField = styled(Field)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 36px;

  ${media.xs} {
    margin-bottom: 26px;
  }

  label {
    font-size: ${fontSize.md};
    font-weight: normal;
    line-height: 32px;
    color: #6b7696;
    margin-bottom: 22px;

    ${media.xs} {
      font-size: ${fontSize.sm};
      margin-bottom: 9px;
    }
  }

  input {
    font-size: 36px;
    line-height: 1;
    border: 3px solid #e3e5eb;
    background-color: ${color.text.tertiary};
    padding-top: 9px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 13px;

    ${media.xs} {
      font-size: ${fontSize.lg};
      padding-top: 5px;
      padding-bottom: 10px;
    }

    &::placeholder {
      color: #838791;
    }
  }
`;

export const TotalCost = styled.ul`
  text-align: left;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 45px;

  ${media.xs} {
    margin-bottom: 41px;
  }
`;

export const TotalCostItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSize.md};
  line-height: 32px;
  margin-bottom: 25px;

  ${media.xs} {
    font-size: ${fontSize.sm};
    line-height: 28px;
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TotalCostText = styled.p`
  color: #6b7696;
  margin-top: 0;
  margin-bottom: 0;
`;

export const TotalCostPasedBack = styled.span`
  color: ${color.primary};
`;

export const TotalCostValue = styled.span`
  color: #2b303d;
`;

export const TotalSaved = styled.div`
  text-align: center;
  max-width: 400px;
  background: #f7feff;
  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  padding-right: 16px;
  padding-left: 16px;

  ${media.xs} {
    margin-bottom: 24px;
  }
`;

export const TotalSavedText = styled.p`
  font-size: ${fontSize.sm};
  line-height: 28px;
  color: #2b303d;
  margin-top: 0;
  margin-bottom: 0;
`;

export const TotalSavedValue = styled.span`
  font-size: 52px;
  line-height: 68px;
  color: ${color.primary};

  @media only screen and (max-width: 374.98px) {
    font-size: 38px;
  }
`;

export const CalculatorFooter = styled.div`
  font-size: ${fontSize.xs};
  line-height: 24px;
  text-align: left;
  color: #6b7696;
`;

export const CalculatorFooterLink = styled.span`
  position: relative;
  border-bottom: 1px solid #6b7696;
  cursor: pointer;
  transition: color ${transition.base};

  &:hover {
    color: ${color.primary};
    border-bottom: none;
  }
`;

export const Triangle = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;

  ${media.md} {
    display: none;
  }
`;

export const StyledModal = styled(Modal)`
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 32px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 44px;

  & > div {
    width: 100%;
  }

  button {
    top: 36px;
    right: 45px;
  }
`;

export const ModalTitle = styled.span`
  display: block;
  font-size: ${fontSize.lg};
  line-height: 38px;
  margin-bottom: 38px;
`;

export const ModalTable = styled.div`
  font-size: ${fontSize.sm};
  line-height: 28px;
`;

export const ModalTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ModalTableTitle = styled.span`
  font-weight: 500;
  color: #6b7696;
`;

export const ModalTableItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ModalTableValue = styled.span`
  color: ${color.text.primary};
`;
