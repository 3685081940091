import * as React from 'react';

import { Wrapper, Title, Navigation, NavigationLink } from './footer-column.styles';

export const Column = ({ theme, title, links }) => (
  <Wrapper theme={theme}>
    <Title>{title}</Title>
    <Navigation>
      {links.nodes.length > 0 &&
        links.nodes.map(({ label, url, target }) => (
          <li key={label}>
            <NavigationLink href={url} target={target}>
              {label}
            </NavigationLink>
          </li>
        ))}
    </Navigation>
  </Wrapper>
);
