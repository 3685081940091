import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Calculator } from 'components/pages/savings-calculator/calculator';

import { buildPageMetadata } from '../utils/data-builders';

const SavingsCalculator = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Calculator
        subtitle={acf.savingsCalculatorSubtitle}
        title={acf.savingsCalculatorTitle}
        labelText={acf.savingsCalculatorLabelText}
        footerText={acf.savingsCalculatorFooterText}
        buttonText={acf.savingsCalculatorButtonText}
      />
      <Footer theme="light" />
    </Layout>
  );
};

export default SavingsCalculator;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        savingsCalculatorSubtitle
        savingsCalculatorTitle
        savingsCalculatorLabelText
        savingsCalculatorFooterText
        savingsCalculatorButtonText
      }
    }
  }
`;
