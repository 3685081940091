import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { FooterView } from './footer.view';

export const Footer = ({ theme, withoutBorderTop, withoutTopSection }) => {
  const {
    wpSharedBlock: {
      acf: {
        footerColumn1Title,
        footerColumn2Title,
        footerColumn3Title,
        footerColumn4Title,
        footerColumn5Title,
        footerCopyright,
      },
    },
    column1Links,
    column2Links,
    column3Links,
    column4Links,
    column5Links,
    legalLinks,
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "footer" }) {
          acf {
            footerColumn1Title
            footerColumn2Title
            footerColumn3Title
            footerColumn4Title
            footerColumn5Title
            footerCopyright
          }
        }
        column1Links: allWpMenu(filter: { slug: { eq: "footer-column-1" } }) {
          edges {
            node {
              id
              name
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
        }

        column2Links: allWpMenu(filter: { slug: { eq: "footer-column-2" } }) {
          edges {
            node {
              id
              name
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
        }

        column3Links: allWpMenu(filter: { slug: { eq: "footer-column-3" } }) {
          edges {
            node {
              id
              name
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
        }

        column4Links: allWpMenu(filter: { slug: { eq: "footer-column-4" } }) {
          edges {
            node {
              id
              name
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
        }

        column5Links: allWpMenu(filter: { slug: { eq: "footer-column-5" } }) {
          edges {
            node {
              id
              name
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
        }

        legalLinks: allWpMenu(filter: { slug: { eq: "footer-legal" } }) {
          edges {
            node {
              menuItems {
                nodes {
                  label
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const columns = [
    { title: footerColumn1Title, links: column1Links.edges[0].node.menuItems },
    { title: footerColumn2Title, links: column2Links.edges[0].node.menuItems },
    { title: footerColumn3Title, links: column3Links.edges[0].node.menuItems },
    { title: footerColumn4Title, links: column4Links.edges[0].node.menuItems },
    { title: footerColumn5Title, links: column5Links.edges[0].node.menuItems },
  ];

  return (
    <FooterView
      theme={theme}
      columns={columns}
      copyright={footerCopyright}
      legalLinks={legalLinks.edges[0].node.menuItems}
      withoutBorderTop={withoutBorderTop}
      withoutTopSection={withoutTopSection}
    />
  );
};
