import styled, { css } from 'styled-components';

import { color, fontSize, media, transition } from 'components/_settings/variables';

export const NavigationLink = styled.a`
  display: block;
  font-size: ${fontSize.sm};
  line-height: 1.75;
  text-decoration: none;
  color: #dedcff;
  transition: ${transition.base};

  &:hover {
    color: ${color.primary};
  }
`;

export const Navigation = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Title = styled.h3`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${color.text.tertiary};
  margin-top: 0;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  margin-right: 100px;

  ${media.sm} {
    margin-right: 0;
  }

  ${media.xs} {
    flex-basis: 50%;
    margin-bottom: 30px;
  }

  &:last-child {
    margin-right: 0;
  }
  ${(p) => css`
    ${p.theme === 'light' &&
    css`
      ${Title} {
        font-size: ${fontSize.sm};
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${color.text.secondary};
        text-transform: none;
        margin-bottom: 26px;
      }
      ${NavigationLink} {
        color: ${color.text.secondary};
        &:hover {
          color: ${color.primary};
        }
      }
    `}
  `}
  ${(p) => css`
    ${p.theme === 'dark' &&
    css`
      ${Title} {
        font-size: ${fontSize.sm};
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-transform: none;
        margin-bottom: 26px;
      }
      ${NavigationLink} {
        color: #ffffff;
        &:hover {
          color: ${color.primary};
        }
      }
    `}
  `}
`;
