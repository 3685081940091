/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';

import TriangleSvgUrl from 'assets/images/pages/savings-calculator/triangle-gray.url.svg';
import getUrlParameter from 'utils/get-url-parameter';

import {
  Wrapper,
  Inner,
  StyledSubtitle,
  StyledTitle,
  CalculatorWrapper,
  StyledField,
  TotalCost,
  TotalCostItem,
  TotalCostText,
  TotalCostPasedBack,
  TotalCostValue,
  TotalSaved,
  TotalSavedText,
  TotalSavedValue,
  CalculatorFooter,
  CalculatorFooterLink,
  Triangle,
  StyledModal,
  ModalTitle,
  ModalTable,
  ModalTableHeader,
  ModalTableTitle,
  ModalTableItem,
  ModalTableValue,
} from './calculator.styles';

const tableSupplierFeesItems = [
  { volume: '£500,000.00', fees: '£36,000.00' },
  { volume: '£750,000.00', fees: '£44,000.00' },
  { volume: '£1,000,000.00', fees: '£50,000.00' },
  { volume: '£1,500,000.00', fees: '£62,000.00' },
  { volume: '£2,000,000.00', fees: '£71,000.00' },
  { volume: '£3,000,000.00', fees: '£87,000.00' },
  { volume: '£4,000,000.00', fees: '£100,000.00' },
  { volume: '£6,000,000.00', fees: '£123,000.00' },
];

export const Calculator = ({ subtitle, title, labelText, footerText, buttonText }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState('');

  const [giftCardVolume, setGiftCardVolume] = useState(500000);
  const [totalCost, setTotalCost] = useState(0);
  const [totalSaved, setTotalSaved] = useState(0);

  const breakageLevel = 0.25;
  const breakagePassed = 0.8;
  const discountAvailable = 0.06;

  const formatNumberToCurrency = (value) => `${currencySymbol}${value.toLocaleString()}`;

  const getAnnualFee = (value) => Math.ceil((Math.sqrt(value) * 50) / 1000) * 1000;

  const calculateTotalSaved = (gcv, tc) => gcv - tc;

  const calculateTotalCost = (gcv) => {
    const annualFee = getAnnualFee(gcv);
    const tc = Math.floor(
      gcv + annualFee - gcv * breakageLevel * breakagePassed - gcv * discountAvailable
    );
    return tc;
  };

  useEffect(() => {
    const tc = calculateTotalCost(giftCardVolume);
    const ts = calculateTotalSaved(giftCardVolume, tc);
    setTotalCost(tc);
    setTotalSaved(ts);
  }, [giftCardVolume]);

  const convertCurrencyNameToSymbol = (name) => {
    switch (name) {
      case 'usd':
        return '$';
      case 'gbp':
        return '£';
      default:
        return '£';
    }
  };
  useEffect(() => {
    const currencyName = getUrlParameter('currency');
    setCurrencySymbol(convertCurrencyNameToSymbol(currencyName));
  }, []);

  const handleChange = (e) => {
    const regexp = /\D/g;
    const value = Number(e.currentTarget.value.replace(regexp, ''));
    calculateTotalCost(value);
    setGiftCardVolume(value);
  };

  const handleModalWindow = () => setModalIsOpen(!modalIsOpen);

  return (
    <Wrapper>
      <Inner>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        <StyledTitle size="xl">{title}</StyledTitle>

        <CalculatorWrapper>
          <StyledField
            className="input-wrapper"
            type="text"
            name="calculator"
            label={labelText}
            value={formatNumberToCurrency(giftCardVolume)}
            onChange={handleChange}
          />

          <TotalCost>
            <TotalCostItem>
              <TotalCostText>Discount passed back</TotalCostText>
              <TotalCostPasedBack>100%</TotalCostPasedBack>
            </TotalCostItem>
            <TotalCostItem>
              <TotalCostText>Unclaimed gift card value passed back</TotalCostText>
              <TotalCostPasedBack>80%</TotalCostPasedBack>
            </TotalCostItem>
            <TotalCostItem>
              <TotalCostText>Total Cost</TotalCostText>
              <TotalCostValue>{formatNumberToCurrency(totalCost)}</TotalCostValue>
            </TotalCostItem>
          </TotalCost>

          <TotalSaved>
            <TotalSavedText>Total Saved with WeGift</TotalSavedText>
            <TotalSavedValue>{formatNumberToCurrency(totalSaved)}</TotalSavedValue>
          </TotalSaved>

          <CalculatorFooter>
            {footerText}{' '}
            <CalculatorFooterLink onClick={handleModalWindow}>{buttonText}</CalculatorFooterLink>
          </CalculatorFooter>
        </CalculatorWrapper>

        <Triangle src={TriangleSvgUrl} alt="" aria-hidden />
      </Inner>

      {modalIsOpen && (
        <StyledModal onClose={handleModalWindow}>
          <ModalTitle>Supplier fees</ModalTitle>

          <ModalTable>
            <ModalTableHeader>
              <ModalTableTitle>Gift card volume</ModalTableTitle>
              <ModalTableTitle>Annual SaaS Fees</ModalTableTitle>
            </ModalTableHeader>

            {tableSupplierFeesItems.map(({ volume, fees }, index) => (
              <ModalTableItem key={index}>
                <ModalTableValue>{volume}</ModalTableValue>
                <ModalTableValue>{fees}</ModalTableValue>
              </ModalTableItem>
            ))}
          </ModalTable>
        </StyledModal>
      )}
    </Wrapper>
  );
};
